import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostList from '../components/partials/single-post'
import "../styles/pages/blog.scss"
import CategoriesMenu from "../components/partials/categoriesMenu";
// import Pagination from "../components/partials/pagination";


const Category = props => {
  const { data, pageContext } = props
  const { edges: posts } = data.allWordpressPost
  const { name: category } = pageContext

  return (
    <Layout>
      <SEO title={`Blog - ${category} category`} />
      <div className="blog">
        <div className="container">
          <h1 className="title">BLOG filtered by {`${category}`}</h1>

          <CategoriesMenu/>
          <PostList posts={posts} />
          {/*<Pagination pageContext={pageContext} pathPrefix="/" />*/}

        </div>
      </div>
    </Layout>
  )
}

export default Category

export const pageQuery = graphql`
  query CategoryPage($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allWordpressPost(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      totalCount
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`
